import { useContext } from 'react'

import { BodyText3, Heading2, MicroText1 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import ButtonLinkWithTracking from '~/components/ButtonLinkWithTracking'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import { AnalyticsCategory, AnalyticsEvent } from '~/context/AnalyticsContext'
import { AuthContext } from '~/context/AuthContext'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import useAnalytics from '~/hooks/analytics/useAnalytics'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import ChevronRight from '~/images/chevron_right.svg'
import GameGroupIcon from '~/images/Workout/GameGroupIcon.svg'
import { getNextUnplayedGame, getTotalGamesCount, getTotalGamesPlayed } from '~/utils/workoutUtils'

export interface WorkoutCardProps {
  workout: GetTodaysWorkoutQuery
  programComplete?: boolean
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({ workout, programComplete }) => {
  const t = useTranslationForNamespace('home')

  const track = useAnalytics()
  const { hasPremium } = useContext(AuthContext)
  const totalGameCount = getTotalGamesCount(hasPremium)

  const totalGamesPlayed = getTotalGamesPlayed(workout)

  // Prevent the remaining game count going below 0 when the sub status goes from Premium to Free
  const remainingGameCount = totalGameCount - totalGamesPlayed < 0 ? 0 : totalGameCount - totalGamesPlayed

  const nextUnplayedGame = getNextUnplayedGame(workout, hasPremium)
  const nextGame = nextUnplayedGame.nextGame?.game?.slug
  const gameUrl = nextGame ? `/workout` : '/'

  const workoutNotStarted = remainingGameCount === totalGameCount
  const CTA = workoutNotStarted ? 'begin' : 'continue'

  const handleCTAClick = () => {
    if (workoutNotStarted) {
      track(AnalyticsEvent.WorkoutStart, {
        category: AnalyticsCategory.Workout,
        cta: 'begin',
      })
    }
  }

  const WorkoutCardContent = () => {
    if (programComplete) {
      const headingText = t('workoutCard.headings.today.programComplete')
      const subheadingText = t('workoutCard.subheadings.today.programComplete')

      return (
        <Content>
          <Heading2>{headingText}</Heading2>
          <BodyText3>{subheadingText}</BodyText3>
        </Content>
      )
    }

    if (remainingGameCount === 0) {
      const headingText = t('workoutCard.headings.today.workoutComplete')
      const subheadingText = t('workoutCard.subheadings.today.workoutComplete')

      return (
        <Content>
          <Heading2>{headingText}</Heading2>
          <BodyText3>{subheadingText}</BodyText3>
        </Content>
      )
    } else {
      const headingText = t('workoutCard.headings.today.workoutIncomplete')

      // Don't include remains if workout hasn't been started
      const gamesRemainingText = workoutNotStarted
        ? t('workoutCard.subheadings.today.gamesRemainingInitial', { count: remainingGameCount })
        : t('workoutCard.subheadings.today.gamesRemaining', { count: remainingGameCount })

      return (
        <Content>
          <Heading2 style={{ width: 'max-content' }}>{headingText}</Heading2>
          <Details>
            <GameGroupIcon title='game_group_icon' />
            <MicroText1>{gamesRemainingText}</MicroText1>
          </Details>
          <CTAButton>
            <ButtonLinkWithTracking
              onClick={handleCTAClick}
              href={gameUrl}
              wide={true}
              textKey={CTA}
              ctaTextSnakeCase={CTA}
              rightBadge={<ChevronRight title={CTA} height='12px' />}
            />
          </CTAButton>
        </Content>
      )
    }
  }

  return (
    <WorkoutCardContainer>
      <WorkoutCardContent />
    </WorkoutCardContainer>
  )
}

export default WorkoutCard

const CTAButton = styled.div`
  padding-top: 20px;
  width: 80%;
  min-width: 230px;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    padding-top: 12px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    padding-top: 12px;
  }
`

const Content = styled.div`
  padding: 5%;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  color: ${({ theme }) => theme.colors.neutral[400]};
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    padding: 20px 50px 30px 50px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    padding: 20px 50px 30px 50px;
  }
`

const Details = styled.div`
  color: ${({ theme }) => theme.colors.neutral[300]};
  display: flex;
  gap: 8px;
`

const WorkoutCardContainer = styled(CardShadowRadius)`
  position: relative;
  max-height: 228px;
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    max-height: 324px;
    height: 100%;
    flex-direction: column;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    max-height: 324px;
    height: 100%;
    flex-direction: column;
  }
`
